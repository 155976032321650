import React from "react"
import EventsSecurityHeroSectionStyle from "./EventsSecurityHeroSectionStyle.module.styl"
import Img from "gatsby-image"

const EventsSecurityheroSection = ({ eventsSecurityHeroSectionImage }) => {
  return (
    <section className={EventsSecurityHeroSectionStyle.hero_section}>
      <Img
        className={EventsSecurityHeroSectionStyle.image}
        fluid={eventsSecurityHeroSectionImage[0].node.childImageSharp.fluid}
      />
      <div className={EventsSecurityHeroSectionStyle.title_wrapper}>
        <h1>Охрана на мероприятия</h1>
      </div>
    </section>
  )
}

export default EventsSecurityheroSection
