import React from "react"
import EventsSecurityInfoSectionStyle from "./EventsSecurityInfoSectionStyle.module.styl"
import Img from "gatsby-image"

const EventsSecurityInfoSection = ({ eventsSecurityInfoSectionImage }) => {
  return (
    <section className={EventsSecurityInfoSectionStyle.info_section}>
      <div className={EventsSecurityInfoSectionStyle.intro_section_content}>
        <Img
          className={EventsSecurityInfoSectionStyle.intro_img}
          fluid={eventsSecurityInfoSectionImage[0].node.childImageSharp.fluid}
        />
        <div className={EventsSecurityInfoSectionStyle.intro_text}>
          <span>Охраната на мероприятия</span> предлагана от „Спешъл Сикюрити
          Груп“ ООД е услуга, която се осъществява от нашите професионално
          обучени и оборудвани специалисти. При масови събирания и събития е
          задължително присъствието на охрана, която гарантира процеса на
          организация от начало до край.
        </div>
      </div>
    </section>
  )
}

export default EventsSecurityInfoSection
