import React from "react"
import EventsSecurityheroSection from "./eventsSecurityHeroSection/EventsSecurityheroSection"
import EventsSecurityInfoSection from "./eventsSecurityInfoSection/EventsSecurityInfoSection"
import EventsSecurityAdvantagesSection from "./eventsSecurityAdvantagesSection/EventsSecurityAdvantagesSection"
import EventsSecuritySliderSection from "./eventsSecuritySliderSection/EventsSecuritySliderSection"

const EventsSecurityTemplate = ({
  eventsSecurityHeroSectionImage,
  eventsSecurityInfoSectionImage,
}) => {
  return (
    <>
      <EventsSecurityheroSection
        eventsSecurityHeroSectionImage={eventsSecurityHeroSectionImage}
      />
      <EventsSecurityInfoSection
        eventsSecurityInfoSectionImage={eventsSecurityInfoSectionImage}
      />
      <EventsSecurityAdvantagesSection />
      <EventsSecuritySliderSection />
    </>
  )
}

export default EventsSecurityTemplate
