import React from "react"
import EventsSecurityAdvantagesSectionStyle from "./EventsSecurityAdvantagesSectionStyle.module.styl"
import Advantage from "../../../../common/packages/advantage/Advantage"

const EventsSecurityAdvantagesSection = props => {
  return (
    <section
      className={EventsSecurityAdvantagesSectionStyle.advantages_section}
    >
      <div className={EventsSecurityAdvantagesSectionStyle.advantages_content}>
        <h2>Защо да изберете тази услуга ?</h2>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <Advantage number={1} advantage={"Охрана на спортни събития"} />{" "}
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <Advantage
              number={2}
              advantage={"Охрана на културни и масови събития"}
            />{" "}
          </div>{" "}
        </div>
      </div>
    </section>
  )
}

export default EventsSecurityAdvantagesSection
