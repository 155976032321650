import React from "react"
import { graphql } from "gatsby"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import EventsSecurityTemplate from "../modules/services/eventsSecurity/EventsSecurity.template"

const OhranaNaMeropriatia = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={"Охрана на мероприятия"}
        keywords={
          "СОТ Ямбол, охрана Ямбол, СОД ямбол, Видеонаблюдение,охрана на обекти Ямбол, физическа охрана Ямбол, цени на СоТ Ямбол"
        }
      />
      <EventsSecurityTemplate
        eventsSecurityHeroSectionImage={
          data.eventsSecurityHeroSectionImage.edges
        }
        eventsSecurityInfoSectionImage={
          data.eventsSecurityInfoSectionImage.edges
        }
      />
    </Layout>
  )
}

export default OhranaNaMeropriatia

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    eventsSecurityHeroSectionImage: allFile(
      filter: { relativeDirectory: { eq: "eventsSecurityHeroSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    eventsSecurityInfoSectionImage: allFile(
      filter: { relativeDirectory: { eq: "eventsSecurityInfoSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
